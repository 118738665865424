import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H1, H4 } from '../../common/Typography';
import Grid from '../../common/Grid';
import SplitLines from '../../common/SplitLines';

import { minWidth, maxWidth, betweenWidth } from '../../../utils/breakpoints';
import { fadeIn, fadeInRight } from '../../../utils/transitions';
import useWaypoint from '../../../hooks/useWaypoint';

const Wrapper = styled(Grid)`
  overflow: hidden;
`;

const Title = styled(H1)`
  ${maxWidth('tabletLandscape')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletLandscape')} {
    grid-column: col / span 6;
  }
`;

const Text = styled(H4)`
  ${maxWidth('tabletPortrait')} {
    grid-column: col / span 12;
  }
  ${betweenWidth('tabletPortrait', 'tabletLandscape')} {
    grid-column: col / span 10;
  }
  ${minWidth('tabletLandscape')} {
    grid-column: col 7 / span 6;
    grid-row: 2;
  }
`;

export default function Hero({ className, title, text }) {
  const [ref, visible] = useWaypoint({ waitForPageTransition: false });
  return (
    <Wrapper ref={ref} className={className} paddedTop>
      <Title css={fadeInRight(visible)}>{title}</Title>
      <Text as="p" css={fadeIn(visible, 2)}>
        <SplitLines visible={visible}>{text}</SplitLines>
      </Text>
    </Wrapper>
  );
}

Hero.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
