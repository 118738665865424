import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '../components/common/Seo';
import localize from '../components/common/localize';
import Hero from '../components/pages/projects/Hero';
import ProjectsList from '../components/pages/projects/ProjectsList';
import PreFooter from '../components/common/PreFooter';

function Projects({ data }) {
  const { page } = data;
  return (
    <>
      <SEO title={page.title} description={page.description} />
      <Hero title={page.title} text={page.introduction} />
      <ProjectsList projects={page.caseStudies} />
      <PreFooter />
    </>
  );
}

export default localize(Projects);

export const query = graphql`
  query {
    page: sanityProjects {
      title {
        localized
      }
      description {
        localized
      }
      introduction {
        localized
      }
      caseStudies {
        id
        name
        slug {
          current
        }
        category {
          name {
            localized
          }
        }
        image {
          asset {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 700
              height: 663
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`;

Projects.propTypes = {
  data: PropTypes.object.isRequired,
};
