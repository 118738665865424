import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { duration } from '../../utils/transitions';

const Outer = styled.div`
  overflow: hidden;
`;

const Inner = styled.div`
  transition: transform ${duration}s ease;
  ${Outer}:hover & {
    transform: scale(1.1);
  }
`;

export default function ScaleOnHover({ className, children }) {
  return (
    <Outer className={className}>
      <Inner>{children}</Inner>
    </Outer>
  );
}

ScaleOnHover.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
