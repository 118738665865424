import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import { H4, H6 } from '../../common/Typography';
import IntLink from '../../common/IntLink';
import ScaleOnHover from '../../common/ScaleOnHover';
import VerticalPan from '../../common/VerticalPan';

import { minWidth, maxWidth, betweenWidth } from '../../../utils/breakpoints';
import { fadeIn } from '../../../utils/transitions';
import useWaypoint from '../../../hooks/useWaypoint';

import FadeIn from '../../common/FadeIn';

const Inner = styled(IntLink)`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 1.5em;
  ${minWidth('tabletPortrait')} {
    max-width: 82.55%;
  }
`;

const Img = styled(ScaleOnHover)`
  grid-column: span 12;
`;

const Meta = styled.div`
  ${maxWidth('tabletPortrait')} {
    grid-column: span 12;
  }
  ${betweenWidth('mobile', 'tabletPortrait')} {
    grid-column: span 10;
  }
  ${minWidth('tabletPortrait')} {
    grid-column: span 10;
  }
`;

const Category = styled(H6)`
  color: ${({ theme }) => theme.colors.greyDark};
`;

export default function ProjectPreview({
  className,
  name,
  image,
  category,
  slug,
  index,
}) {
  const isFirstOrSecond = [0, 1].includes(index);

  const [ref, visible] = useWaypoint({
    waitForPageTransition: false,
    threshold: isFirstOrSecond ? 0 : undefined,
  });

  return (
    <li ref={ref} className={className}>
      <Inner to={`/projects/${slug}/`} background="transparent">
        <Img css={fadeIn(visible, isFirstOrSecond ? 3 : 1)}>
          <VerticalPan>
            <FadeIn active={visible} order={isFirstOrSecond ? 3 : 1}>
              <GatsbyImage image={image.asset.gatsbyImageData} alt={name} />
            </FadeIn>
          </VerticalPan>
        </Img>
        <Meta css={fadeIn(visible, isFirstOrSecond ? 3 : 1)}>
          <Category as="h3">{category}</Category>
          <H4 as="p">{name}</H4>
        </Meta>
      </Inner>
    </li>
  );
}

ProjectPreview.propTypes = {
  category: PropTypes.string.isRequired,
  className: PropTypes.string,
  image: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
