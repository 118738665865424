import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fluidRange } from 'polished';
import ProjectPreview from './ProjectPreview';

import Grid from '../../common/Grid';
import UnstyledList from '../../common/UnstyledList';

import { minWidth, maxWidth } from '../../../utils/breakpoints';
import { breakpoints } from '../../../utils/theme';

const Wrapper = styled(Grid)`
  ${fluidRange({
    prop: 'grid-row-gap',
    fromSize: '60px',
    toSize: '100px',
  })};
  ${fluidRange({
    prop: 'padding-bottom',
    fromSize: '40px',
    toSize: '400px',
  })}
`;

const Preview = styled(ProjectPreview)`
  ${maxWidth('tabletPortrait')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletPortrait')} {
    grid-column-end: span 6;
    &:nth-child(odd) {
      grid-column-start: col;
    }
    &:nth-child(even) {
      position: relative;
    }
  }
  ${fluidRange(
    {
      prop: 'top',
      fromSize: '100px',
      toSize: '200px',
    },
    `${breakpoints.tabletPortrait}px`,
    `${breakpoints.laptop}px`
  )};
`;

export default function ProjectsList({ className, projects }) {
  return (
    <Wrapper className={className} paddedTop paddedBottom as={UnstyledList}>
      {projects.map((caseStudy, i) => (
        <Preview
          key={i}
          index={i}
          name={caseStudy.name}
          image={caseStudy.image}
          category={caseStudy.category.name}
          slug={caseStudy.slug.current}
        />
      ))}
    </Wrapper>
  );
}

ProjectsList.propTypes = {
  className: PropTypes.string,
  projects: PropTypes.array.isRequired,
};
