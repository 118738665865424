import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { easeInOut } from '../../utils/easings';

import { duration, stagger } from '../../utils/transitions';

const Outer = styled.div`
  overflow: hidden;
`;

const Inner = styled.div`
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: ${(props) => `opacity ${duration}s ${easeInOut}
  ${((props.order - 1) * (props.active ? stagger + props.delay : 0)).toFixed(
    1
  )}s;`};
`;

export default function FadeIn({
  className,
  active,
  order = 1,
  delay = 0,
  children,
}) {
  return (
    <Outer className={className}>
      <Inner active={active} order={order} delay={delay}>
        {children}
      </Inner>
    </Outer>
  );
}

FadeIn.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool.isRequired,
  order: PropTypes.number,
  delay: PropTypes.number,
  children: PropTypes.node.isRequired,
};
