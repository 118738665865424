import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useScrollPercentage } from 'react-scroll-percentage';

const Wrapper = styled.div`
  overflow: hidden;
  height: 0;
  padding-bottom: 75%;
  position: relative;
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`;

export default function VerticalPan({ className, children }) {
  const [$percentage, percentage] = useScrollPercentage();
  return (
    <Wrapper className={className} ref={$percentage}>
      <Inner style={{ transform: `translateY(${percentage * -20}%)` }}>
        {children}
      </Inner>
    </Wrapper>
  );
}

VerticalPan.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
